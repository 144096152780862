class KENHeaderV2Navigation {
  header = document.querySelector('.ken-header-v2');

  navItems = this.header.querySelectorAll('.js-ken-header-v2__nav-item');

  menuItems = this.header.querySelectorAll('.js-ken-header-v2__menu-item');

  burgerButton = this.header.querySelector('.ken-icon--hamburger');

  mobileNavItems = this.header.querySelectorAll(
    '.ken-header-v2__menu-mobile__nav-item',
  );

  mobileCustomerService = this.header.querySelector(
    '#js-ken-header-v2__menu-mobile__button--wishlist',
  );

  addEventListenersMobile = () => {
    if (!this.burgerButton) return;

    this.burgerButton.addEventListener('click', () => {
      this.burgerButton.classList.toggle('--show-menu-mobile');
      this.burgerButton.classList.toggle('ken-icon--hamburger');
      this.burgerButton.classList.toggle('ken-icon--close');
    });

    [...this.mobileNavItems].forEach(navItem => {
      const screen = navItem.querySelector('.ken-header-v2__menu-screen');
      if (!screen) return;

      navItem.addEventListener('click', event => {
        event.stopPropagation();
        navItem.classList.add('--show-screen');
      });

      screen.addEventListener('click', event => {
        event.stopPropagation();
      });

      const titleBox = screen.querySelector(
        '.ken-header-v2__menu-screen__title-box',
      );
      titleBox.addEventListener('click', () => {
        navItem.classList.remove('--show-screen');
      });
    });

    this.mobileCustomerService.addEventListener('click', event => {
      event.stopPropagation();
      this.mobileCustomerService.classList.add('--show-screen');
    });

    const screen = this.mobileCustomerService.querySelector(
      '.ken-header-v2__menu-screen',
    );
    screen.addEventListener('click', event => {
      event.stopPropagation();
    });

    const titleBox = screen.querySelector(
      '.ken-header-v2__menu-screen__title-box',
    );
    titleBox.addEventListener('click', () => {
      this.mobileCustomerService.classList.remove('--show-screen');
    });
  };

  addEventListenersDesktop = () => {
    if (!this.header || !this.navItems.length) return;

    [...this.navItems].forEach(item => {
      item.addEventListener('click', () => {
        if (item.classList.contains('--active')) {
          [...this.navItems].forEach(nav => {
            nav.classList.remove('--active');
            nav.classList.remove('--inactive');
          });
        } else {
          [...this.navItems].forEach(nav => {
            nav.classList.remove('--active');
            nav.classList.add('--inactive');
          });
          item.classList.remove('--inactive');
          item.classList.add('--active');
        }
      });
    });

    [...this.menuItems].forEach(item => {
      item.addEventListener('click', event => {
        event.stopPropagation();
      });
    });
  };

  init = () => {
    this.addEventListenersMobile();
    this.addEventListenersDesktop();
  };
}

export default () => {
  const header = document.querySelector('.ken-header-v2');
  if (!header) return;
  const kenHeaderV2 = new KENHeaderV2Navigation();
  kenHeaderV2.init();
};
